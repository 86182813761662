import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

class SalesService {
  async list() {
    const options = {
      headers: {
        'content-type': 'application/json',
      },
    };

    return axios.get(API_URL + 'sales', options);
  }
}

export default new SalesService();
