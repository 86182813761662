<template>
  <div class="container">
    <div class="form-group">
      <div v-if="message" class="alert alert-danger" role="alert">
        {{ message }}
      </div>
    </div>
    <div class="login">
      <div class="shadow-wrapper">
        <div class="header">
          <img :src="zeusLogo" alt="App Logo" />
        </div>
        <div class="login-content">
          <Form @submit="handleLogin" :validation-schema="schema">
            <div class="form-outline mb-4">
              <label for="username">{{ $t('username') }}</label>
              <Field name="username" type="email" autocomplete="username" class="form-control" />
              <ErrorMessage name="username" class="error-feedback" />
            </div>
            <div class="form-outline mb-3">
              <label for="password">{{ $t('password') }}</label>
              <Field name="password" type="password" autocomplete="current-password" class="form-control" />
              <ErrorMessage name="password" class="error-feedback" />
            </div>
            <div class="text-center text-lg-start mt-4 pt-2">
              <button class="btn btn-primary btn-block button-login" :disabled="loading">
                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                {{ $t('login') }}
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>

    <div class="footer">
      <span>{{ $t('zeus') }} {{ new Date().getFullYear() }} &copy; {{ $t('footer-rights') }}</span>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },

  beforeRouteLeave() {
    document.body.style.backgroundColor = '#003478';
  },

  data() {
    document.body.style.backgroundColor = '#ebf0f5';
    const schema = yup.object().shape({
      username: yup.string().email(this.$i18n.t('email-valid')).required(this.$i18n.t('username-mandatory')),

      password: yup.string().required(this.$i18n.t('password-mandatory')),
    });

    return {
      zeusLogo: require('../assets/images/zeus-logo.svg'),
      loading: false,
      message: '',
      schema,
    };
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/product');
    }
  },

  methods: {
    handleLogin(user) {
      this.loading = true;

      this.$store.dispatch('auth/login', user).then(
        () => {
          this.$router.push('/product');
        },
        (error) => {
          this.loading = false;
          this.message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/variables.scss';

.alert-danger {
  margin-top: 15px;
  color: $red;
  border-color: $red;
}

div.shadow-wrapper {
  width: 410px;
  border-radius: 10px;
  box-shadow: 0px 1px 15px 1px $secondary;
}

div.login {
  width: 410px;
  margin: 0 auto;
  vertical-align: center;
  margin-top: 20%;
  margin-bottom: 80px;
}

.login-content {
  background-color: $white;
  margin: 0 auto;
  vertical-align: center;
  padding: 33px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.login .header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: $blue-zeus;
  font-weight: bold;
  font-size: 61px;
  height: 100px;
  color: $white;
  text-align: center;
  padding-top: 3px;

  img {
    margin-top: 15px;
    vertical-align: revert;
  }
}

.button-login {
  height: 48px;
  background-color: $primary;
  border: $primary;
  width: 100%;
}

div.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: $neutral-50;
  height: 50px;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: $neutral-90;

  span {
    text-align: center;
    margin: 0 auto;
  }
}
</style>
