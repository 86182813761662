import BalanceService from '../services/balance.service';

const initialState = {};

export const balance = {
  namespaced: true,
  state: initialState,

  actions: {
    getBalance({ commit }) {
      return BalanceService.getBalance().then(
        (data) => {
          commit('getBalanceSuccess', data);
          return Promise.resolve(data);
        },
        (error) => {
          commit('getBalanceFailure');
          return Promise.reject(error);
        }
      );
    },

    getBalanceStatistics({ commit }) {
      return BalanceService.getBalanceStatistics().then(
        (data) => {
          commit('getBalanceStatisticsSuccess', data);
          return Promise.resolve(data);
        },
        (error) => {
          commit('getBalanceStatisticsFailure');
          return Promise.reject(error);
        }
      );
    },
  },

  mutations: {
    getBalanceSuccess(state, balance) {
      state.balance = balance.data.balance;
    },

    getBalanceStatisticsSuccess(state, balanceStatistics) {
      state.balanceStatistics = balanceStatistics.data;
    },

    getBalanceFailure(state) {
      state.balance = null;
    },

    getBalanceStatisticsFailure(state) {
      state.balance = null;
    },
  },
};
