import OfferService from '../services/offer.service';

const initialState = {};

export const offer = {
  namespaced: true,
  state: initialState,

  actions: {
    create({ commit }, offer) {
      return OfferService.create(offer).then(
        (newOffer) => {
          commit('offerCreationSuccess', newOffer);
          return Promise.resolve(newOffer);
        },
        (error) => {
          commit('offerCreationFailure');
          return Promise.reject(error);
        }
      );
    },

    update({ commit }, offer) {
      return OfferService.update(offer).then(
        (updatedOffer) => {
          commit('offerCreationSuccess', updatedOffer);
          return Promise.resolve(updatedOffer);
        },
        (error) => {
          commit('offerCreationFailure');
          return Promise.reject(error);
        }
      );
    },

    list({ commit }) {
      return OfferService.list().then(
        (data) => {
          commit('offerListSuccess', data);
          return Promise.resolve(data);
        },
        (error) => {
          commit('offerListFailure');
          return Promise.reject(error);
        }
      );
    },

    retrieve({ commit }, details) {
      return OfferService.retrieve(details.productId, details.offerId).then(
        (data) => {
          commit('offerRetrieveSuccess', data);
          return Promise.resolve(data);
        },
        (error) => {
          commit('offerRetrieveFailure');
          return Promise.reject(error);
        }
      );
    },

    delete({ commit }, { productId, offerId }) {
      return OfferService.delete(productId, offerId).then(
        () => {
          commit('offerDeleteSuccess');
          return Promise.resolve();
        },
        (error) => {
          commit('offerDeleteFailure');
          return Promise.reject(error);
        }
      );
    },
  },

  mutations: {
    offerCreationSuccess(state, newOffer) {
      state.newOffer = newOffer;
    },

    offerCreationFailure(state) {
      state.newOffer = null;
    },

    offerListSuccess(state, offers) {
      offers.sort((a, b) => a.details.name.localeCompare(b.details.name));
      state.offers = offers;
    },

    offerListFailure(state) {
      state.offers = null;
    },

    offerRetrieveSuccess(state, offer) {
      state.activeOffer = offer;
    },

    offerRetrieveFailure(state) {
      state.activeOffer = null;
    },

    offerDeleteSuccess(state) {
      state.offers = null;
    },

    offerDeleteFailure(state) {
      state.offers = null;
    },
  },
};
