import axios from 'axios';

import authHeader from '../services/auth-header';

class AxiosConfig {
  configure() {
    axios.interceptors.request.use(
      (config) => {
        const auth = authHeader().Authorization;

        if (!config.url.includes('s3.amazonaws.com') && auth) {
          config.headers.authorization = auth;
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401 && !(location.pathname === '/login')) {
          localStorage.clear();
          sessionStorage.clear();
          location.reload();
        }

        return Promise.reject(error);
      }
    );
  }
}

export default new AxiosConfig();
