class PaymentUtils {
  createCardPayload(clientData, product, cardData, cardExpirationDate) {
    return {
      customer: {
        name: clientData.name,
        email: clientData.email,
        cpfCnpj: clientData.cpfCnpj,
        phone: clientData.phone,
      },
      payment: {
        billingType: 'CREDIT_CARD',
        installmentCount: cardData.selectedInstallments,
        creditCard: {
          holderName: cardData.ccname,
          number: cardData.cardnumber,
          expiryMonth: cardExpirationDate[0],
          expiryYear: `20${cardExpirationDate[1]}`,
          ccv: cardData.cvc,
        },
        creditCardHolderInfo: {
          name: clientData.name,
          email: clientData.email,
          cpfCnpj: clientData.cpfCnpj,
          postalCode: clientData.postcode,
          addressNumber: clientData.addressNumber,
          phone: clientData.phone,
          mobilePhone: clientData.phone,
        },
      },
      offer: {
        offerShortLink: product.shortLink,
      },
    };
  }

  createBoletoPayload(userData, activeProduct) {
    return {
      customer: {
        name: userData.name,
        email: userData.email,
        document: userData.cpfCnpj,
        phone: userData.phone,
      },
      payment: {
        billingType: 'BOLETO',
        installmentCount: activeProduct.details.maxInstallmentsQuantity,
      },
      offer: {
        offerShortLink: activeProduct.shortLink,
      },
    };
  }

  normalizeMoney(value) {
    let values = new String(this._adjustValue(value)).split('.');
    values = this._fillValues(values);

    return `${values[0]},${values[1]}`;
  }

  getInstallmentValue(payment, maxInstallmentsQuantity) {
    let totalValue = payment.totalValue;

    if (maxInstallmentsQuantity === '1') {
      totalValue = payment.oneInstallmentTotalValue;
    }

    const installments = payment.maxInstallmentsQuantity;
    const totalValueAdjusted = this._adjustValue(totalValue / installments);

    return this._fillValues(new String(totalValueAdjusted).split('.'));
  }

  _adjustValue(value) {
    return Math.round(value * 100) / 100;
  }

  _fillValues(values) {
    if (!values[1]) {
      values[1] = '00';
    }

    if (values[1].length === 1) {
      values[1] = `${values[1]}0`;
    }

    values[0] = this._formatThousands(values[0]);

    return values;
  }

  _formatThousands(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
}

export default new PaymentUtils();
