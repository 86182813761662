import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

class ClassroomService {
  async list() {
    const response = await axios.get(API_URL + 'classrooms');
    return Promise.resolve(response.data);
  }
}

export default new ClassroomService();
