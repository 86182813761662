import ProductService from '../services/product.service';

const initialState = {
  newProduct: null,
};

export const product = {
  namespaced: true,
  state: initialState,

  actions: {
    uploadFile({ commit }, file) {
      return ProductService.uploadFile(file).then(
        (uploadInfo) => {
          commit('uploadFileSuccess', uploadInfo);
          return Promise.resolve(uploadInfo);
        },
        (error) => {
          commit('uploadFileFailure');
          return Promise.reject(error);
        }
      );
    },

    createProduct({ commit }, product) {
      return ProductService.create(product).then(
        (newProduct) => {
          commit('productCreationSuccess', newProduct);
          return Promise.resolve(newProduct);
        },
        (error) => {
          commit('productCreationFailure');
          return Promise.reject(error);
        }
      );
    },

    listProducts({ commit }) {
      return ProductService.list().then(
        (data) => {
          commit('productListSuccess', data);
          return Promise.resolve(data);
        },
        (error) => {
          commit('productListFailure');
          return Promise.reject(error);
        }
      );
    },

    deleteProduct({ commit }, productId) {
      return ProductService.delete(productId).then(
        () => {
          commit('productDeleteSuccess');
          return Promise.resolve();
        },
        (error) => {
          commit('productDeleteFailure');
          return Promise.reject(error);
        }
      );
    },

    findByShortLink({ commit }, shortLink) {
      return ProductService.findByShortLink(shortLink).then(
        (data) => {
          commit('productByShortLinkSuccess', data);
          return Promise.resolve(data);
        },
        (error) => {
          commit('productByShortLinkFailure');
          return Promise.reject(error);
        }
      );
    },
  },

  mutations: {
    uploadFileSuccess(state, uploadInfo) {
      state.uploadInfo = uploadInfo;
    },

    uploadFileFailure(state) {
      state.file = null;
    },

    productCreationSuccess(state, newProduct) {
      state.newProduct = newProduct;
    },

    productCreationFailure(state) {
      state.newProduct = null;
    },

    productListSuccess(state, products) {
      products.sort((a, b) => a.name.localeCompare(b.name));
      state.products = products;
    },

    productListFailure(state) {
      state.products = null;
    },

    productDeleteSuccess(state) {
      state.products = null;
    },

    productDeleteFailure(state) {
      state.products = null;
    },

    productByShortLinkSuccess(state, product) {
      state.shortLinkProduct = product.data;
    },

    productByShortLinkFailure(state) {
      state.shortLinkProduct = null;
    },
  },
};
