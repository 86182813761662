<template>
  <div id="oval">
    <img :src="trgLogo" alt="Seller logo" />
  </div>
  <div class="content">
    <div class="row">
      <div class="col col-auto">
        <img v-if="currentProduct" :src="currentProduct?.product.logo" class="product-logo" height="89" />
      </div>
      <div class="col product-info">
        <p v-if="!currentProduct?.details.useOfferNameAtCheckout" class="title">{{ currentProduct?.product.name }}</p>
        <p v-else class="title">{{ currentProduct?.details.name }}</p>
        <p v-if="currentProduct" class="boleto">
          {{ currentProduct.details.paymentType === 'CREDIT_CARD' && currentProduct.details.maxInstallmentsQuantity > 1 ? $t('up-to') : '' }}
          {{ currentProduct.details.maxInstallmentsQuantity }}x {{ $t('currency-sign') }}
          <b>{{ getInstallmentValue(currentProduct.details)[0] }},</b>
          <b>{{ getInstallmentValue(currentProduct.details)[1] ? getInstallmentValue(currentProduct.details)[1] : '00' }}</b>
          {{ currentProduct.details.paymentType === 'BOLETO' ? $t('boleto-offer-description') : $t('credit-card-offer-description') }}
        </p>
      </div>
      <div class="col col-auto">
        <img :src="lock" alt="Lock logo" class="lock" align="right" />
      </div>
    </div>
    <div class="col col-auto product-description">
      <p>{{ currentProduct?.product.description }}</p>
    </div>
    <hr />

    <div v-if="showClientForm" class="form-wrapper">
      <Form @submit="handleClientData" id="form-client-data" :validation-schema="schema">
        <div class="mb-3">
          <label for="name" class="form-label">{{ $t('name') }}</label>
          <Field name="name" type="text" class="form-control" :placeholder="$t('name-public-hint')" />
          <ErrorMessage name="name" class="error-feedback" />
        </div>
        <div class="mb-3">
          <label for="email" class="form-label">{{ $t('email') }}</label>
          <Field name="email" type="text" class="form-control" :placeholder="$t('email-public-hint')" />
          <ErrorMessage name="email" class="error-feedback" />
        </div>
        <div class="mb-3">
          <label for="cpfCnpj" class="form-label">{{ $t('cpfCnpj') }}</label>
          <Field name="cpfCnpj" type="text" class="form-control" :placeholder="$t('cpfCnpj-public-hint')" v-maska="'###.###.###-##'" />
          <ErrorMessage name="cpfCnpj" class="error-feedback" />
        </div>
        <div class="mb-3">
          <label for="phone" class="form-label">{{ $t('phone') }}</label>
          <Field name="phone" type="text" class="form-control" :placeholder="$t('phone-public-hint')" v-maska="'(##) # ####-####'" />
          <ErrorMessage name="phone" class="error-feedback" />
        </div>
        <div class="form-inline">
          <div class="form-group mb-3">
            <label for="postcode" class="form-label">{{ $t('postcode') }}</label>
            <Field name="postcode" type="text" class="form-control" :placeholder="$t('postcode-hint')" v-maska="'#####-###'" />
            <ErrorMessage name="postcode" class="error-feedback" />
          </div>
          <div class="form-group mb-3">
            <label for="addressNumber" class="form-label">{{ $t('address-number') }}</label>
            <Field name="addressNumber" type="text" class="form-control" :placeholder="$t('address-number-hint')" v-maska="'#####'" />
            <ErrorMessage name="addressNumber" class="error-feedback" />
          </div>
        </div>
      </Form>
    </div>

    <div v-if="!showClientForm" class="form-wrapper">
      <Form @submit="handleClientCardData" id="form-client-card-data" :validation-schema="getCardSchema()">
        <div class="card-container">
          <vue-paycard :value-fields="valueFields" :is-card-number-masked="true" :has-random-backgrounds="false" :labels="labels" />
        </div>
        <div class="mb-3">
          <label for="cardnumber" class="form-label">{{ $t('card-number') }}</label>
          <Field
            :id="inputFields.cardNumber"
            @input="changeCardNumber"
            name="cardnumber"
            type="text"
            class="form-control"
            :placeholder="$t('card-number-mask')"
            :autocomplete="'cc-number'"
            v-maska="'#### #### #### ####'"
            data-card-field
          />
          <ErrorMessage name="cardnumber" class="error-feedback" />
        </div>
        <div class="mb-3">
          <label for="ccname" class="form-label">{{ $t('card-holder-name') }}</label>
          <Field
            :id="inputFields.cardName"
            @input="changeCardName"
            name="ccname"
            type="text"
            class="form-control"
            :placeholder="$t('card-holder-hint')"
            :autocomplete="'cc-name'"
            data-card-field
          />
          <ErrorMessage name="ccname" class="error-feedback" />
        </div>
        <div class="form-inline">
          <div class="form-group mb-3">
            <label for="cc-exp" class="form-label">{{ $t('card-expiration-date') }}</label>
            <Field
              :id="inputFields.cardMonth"
              @input="changeCardExp"
              name="cc-exp"
              type="text"
              class="form-control"
              :placeholder="$t('card-expiration-hint')"
              :autocomplete="'cc-exp'"
              v-maska="'##/##'"
              data-card-field
            />
            <ErrorMessage name="cc-exp" class="error-feedback" />
          </div>
          <div class="form-group mb-3">
            <label for="cvc" class="form-label">{{ $t('card-cvc-code') }}</label>
            <Field
              :id="inputFields.cardCvv"
              @input="changeCardCvc"
              name="cvc"
              type="number"
              class="form-control"
              :placeholder="$t('card-cvc-hint')"
              :autocomplete="'cc-csc'"
              v-maska="'###'"
              data-card-field
            />
            <ErrorMessage name="cvc" class="error-feedback" />
          </div>
        </div>
        <br />
        <div v-if="currentProduct.details.maxInstallmentsQuantity !== '1'" class="form-inline">
          <div class="form-group mb-3">
            <label for="selectedInstallments" class="form-label">{{ $t('choose-installments-quantity') }}</label>

            <div class="installments-option">
              <Field v-if="validInstallments[0]" name="selectedInstallments" type="radio" :value="validInstallments[0]" class="form-check-input left" />
              <label v-if="validInstallments[0]" class="form-label left"
                >{{ validInstallments[0] }}x {{ $t('of') }} {{ $t('currency-sign') }}
                {{ normalizeMoney(currentProduct.details.totalValue / validInstallments[0]) }}</label
              >

              <Field v-if="validInstallments[1]" name="selectedInstallments" type="radio" :value="validInstallments[1]" class="form-check-input right" />
              <label v-if="validInstallments[1]" class="form-label right"
                >{{ validInstallments[1] }}x {{ $t('of') }} {{ $t('currency-sign') }}
                {{ normalizeMoney(currentProduct.details.totalValue / validInstallments[1]) }}</label
              >
            </div>

            <div class="installments-option">
              <Field v-if="validInstallments[2]" name="selectedInstallments" type="radio" :value="validInstallments[2]" class="form-check-input left" />
              <label v-if="validInstallments[2]" class="form-label left"
                >{{ validInstallments[2] }}x {{ $t('of') }} {{ $t('currency-sign') }}
                {{ normalizeMoney(currentProduct.details.totalValue / validInstallments[2]) }}</label
              >

              <Field v-if="validInstallments[3]" name="selectedInstallments" type="radio" :value="validInstallments[3]" class="form-check-input right" />
              <label v-if="validInstallments[3]" class="form-label right"
                >{{ validInstallments[3] }}x {{ $t('of') }} {{ $t('currency-sign') }}
                {{ normalizeMoney(currentProduct.details.totalValue / validInstallments[3]) }}</label
              >
            </div>
            <ErrorMessage name="selectedInstallments" class="error-feedback" />
          </div>
        </div>
      </Form>
    </div>

    <div v-if="currentProduct" class="options">
      <p class="safe-buy">
        <img :src="lock" alt="Menu logo" />
        {{ $t('secure-buying') }}
      </p>
      <div class="observations">
        <p>{{ $t('privacy-note') }}</p>
        <p>{{ $t('product-delivery-note') }}</p>
        <p>{{ $t('support-note') }}</p>
        <div v-if="currentProduct">
          <a v-if="currentProduct.product.termsOfUse" :href="currentProduct.product.termsOfUse" target="_blank">{{ $t('terms-of-use') }}</a>
          <a v-if="currentProduct.product.contractUrl" :href="currentProduct.product.contractUrl" target="_blank">{{ $t('contract-url') }}</a>
          <a v-if="currentProduct.product.privacyPolicy" :href="currentProduct.product.privacyPolicy" target="_blank">{{ $t('privacy-policy') }}</a>
        </div>
      </div>
      <div class="actions">
        <button
          v-if="showClientForm && currentProduct.details.paymentType === 'BOLETO'"
          type="submit"
          form="form-client-data"
          class="next-payment"
          :disabled="loading"
        >
          <span v-show="loading" class="spinner-border spinner-border-sm"></span>{{ $t('generate-boleto') }}
        </button>
        <button v-if="showClientForm && !(currentProduct.details.paymentType === 'BOLETO')" type="submit" form="form-client-data" class="next-payment">
          {{ $t('insert-card-data') }}
        </button>
        <button v-if="!showClientForm" type="submit" form="form-client-card-data" class="next-payment" :disabled="loading">
          <span v-show="loading" class="spinner-border spinner-border-sm"></span>{{ $t('do-payment') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { onUpdated } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { VuePaycard } from 'vue-paycard';

import PaymentUtils from './payment-utils';

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    VuePaycard,
  },

  data() {
    document.body.style.backgroundColor = '#ebf0f5';

    onUpdated(() => {
      const cardPostfix = document.querySelector('.card-item__cvvBand');
      if (cardPostfix) {
        cardPostfix.style.width = '50px';
      }
    });

    const schema = yup.object().shape({
      name: yup.string().required(this.$i18n.t('name-mandatory')),
      email: yup.string().email(this.$i18n.t('email-invalid-format')).required(this.$i18n.t('email-mandatory')),
      cpfCnpj: yup.string().required(this.$i18n.t('cpfCnpj-mandatory')),
      phone: yup.string().required(this.$i18n.t('phone-mandatory')),
      postcode: yup.string().required(this.$i18n.t('postcode-mandatory')),
      addressNumber: yup.string().required(this.$i18n.t('address-number-mandatory')),
    });

    return {
      schema,
      loading: false,
      trgLogo: require('../../assets/images/trg.svg'),
      lock: require('../../assets/images/lock.svg'),
      productImage: require('../../assets/images/product.svg'),
      barcode: require('../../assets/images/barcode.svg'),
      showClientForm: true,
      clientData: {},

      valueFields: {
        cardName: '',
        cardNumber: '',
        cardMonth: '',
        cardYear: '',
        cardCvv: '',
      },

      inputFields: {
        cardNumber: 'v-card-number',
        cardName: 'v-card-name',
        cardMonth: 'v-card-month',
        cardYear: 'v-card-year',
        cardCvv: 'v-card-cvv',
      },

      labels: {
        cardName: this.$i18n.t('card-holder'),
        cardHolder: this.$i18n.t('card-holder-name'),
        cardMonth: this.$i18n.t('card-mm'),
        cardYear: this.$i18n.t('card-yy'),
        cardExpires: this.$i18n.t('card-expiration-date'),
        cardCvv: this.$i18n.t('card-cvc-code'),
      },
    };
  },

  async mounted() {
    const menu = document.querySelector('.menu');
    const mainWrapper = document.getElementById('main');

    if (menu && mainWrapper) {
      menu.parentNode.removeChild(menu);
      mainWrapper.parentNode.removeChild(mainWrapper);
    }

    const shortLink = this.$route.params.shortLink;

    if (shortLink) {
      try {
        await this.$store.dispatch('product/findByShortLink', shortLink);

        const installmentsMap = {
          12: [3, 6, 9, 12],
          11: [3, 6, 9, 11],
          10: [3, 6, 8, 10],
          9: [3, 4, 6, 9],
          8: [3, 4, 5, 8],
          7: [3, 4, 5, 7],
          6: [3, 4, 5, 6],
          5: [2, 3, 4, 5],
          4: [2, 3, 4],
          3: [2, 3],
          2: [2],
        };

        const maxInstallmentsQuantity = this.$store.state.product.shortLinkProduct.details.maxInstallmentsQuantity;
        this.validInstallments = installmentsMap[maxInstallmentsQuantity];
      } catch (e) {
        this.$router.push('/not-found');
      }
    } else {
      this.$router.push('/not-found');
    }
  },

  computed: {
    currentProduct() {
      return this.$store.state.product.shortLinkProduct;
    },
  },

  methods: {
    getCardSchema() {
      const maxInstallmentsQuantity = this.$store.state.product.shortLinkProduct.details.maxInstallmentsQuantity;

      const shape = {
        cardnumber: yup.string().required(this.$i18n.t('card-number-mandatory')),
        ccname: yup.string().required(this.$i18n.t('card-holder-name-mandatory')),
        'cc-exp': yup.string().required(this.$i18n.t('card-expiration-date-mandatory')),
        cvc: yup.string().required(this.$i18n.t('card-cvc-code-mandatory')),
      };

      if (maxInstallmentsQuantity > 1) {
        shape['selectedInstallments'] = yup.string().required(this.$i18n.t('selected-installments-mandatory'));
      }

      return yup.object().shape(shape);
    },

    handleClientData(userData) {
      const activeProduct = this.$store.state.product.shortLinkProduct;
      this.clientData = userData;
      this.$store.state.product.userEmail = userData.email;

      if (activeProduct.details.paymentType === 'BOLETO') {
        this.loading = true;
        const paymentData = PaymentUtils.createBoletoPayload(userData, activeProduct);

        this.$store.dispatch('payment/submit', paymentData).then(
          () => {
            this.loading = false;
            this.$store.state.product.result = 'success';
            this.$router.push('/paid');
          },
          () => {
            this.loading = false;
            this.$store.state.product.result = 'error';
            this.$router.push('/paid');
          }
        );
      } else {
        this.showClientForm = false;
      }
    },

    handleClientCardData(cardData) {
      const clientData = this.clientData;
      const product = this.$store.state.product.shortLinkProduct;
      const cardExpirationDate = cardData['cc-exp'].split('/');
      const paymentData = PaymentUtils.createCardPayload(clientData, product, cardData, cardExpirationDate);

      this.loading = true;

      this.$store.dispatch('payment/submit', paymentData).then(
        () => {
          this.loading = false;
          this.showClientForm = true;
          this.$store.state.product.result = 'success';
          this.$router.push('/paid');
        },
        () => {
          this.loading = false;
          this.showClientForm = true;
          this.$store.state.product.result = 'error';
          this.$router.push('/paid');
        }
      );
    },

    normalizeMoney(value) {
      return PaymentUtils.normalizeMoney(value);
    },

    getInstallmentValue(payment) {
      const product = this.$store.state.product.shortLinkProduct;
      const maxInstallmentsQuantity = product.details.maxInstallmentsQuantity;
      return PaymentUtils.getInstallmentValue(payment, maxInstallmentsQuantity);
    },

    changeCardNumber(e) {
      this.valueFields.cardNumber = e.target.value;
    },

    changeCardName(e) {
      this.valueFields.cardName = e.target.value;
    },

    changeCardExp(e) {
      const values = e.target.value.split('/');
      this.valueFields.cardMonth = values[0];

      if (values[1]) {
        this.valueFields.cardYear = `20${values[1]}`;
      }
    },

    changeCardCvc(e) {
      this.valueFields.cardCvv = e.target.value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../scss/variables.scss';

#oval {
  width: 100%;
  height: 250px;
  background: $public-blue-header;
  border-radius: 100px / 30px;
  margin-top: -30px;

  img {
    margin-left: calc(50% - 143px / 2);
    margin-top: 45px;
  }
}

.content {
  position: absolute;
  width: calc(96% - 2px);
  left: 2%;
  right: 2%;
  top: 70px;
  background: $neutral-00;
  box-shadow: $default-box-shadow;
  border-radius: 4px 40px;

  hr {
    margin-top: 3px;
  }

  img.product-logo {
    margin-left: 9px;
    margin-top: 15px;
  }

  img.lock {
    margin-top: 20px;
    margin-right: 20px;
  }
}

.product-info {
  margin: 15px auto;
  color: $public-blue;

  text-align: center;

  p {
    margin-bottom: 2px;
  }

  p.boleto {
    font-size: 18px;

    b {
      font-size: 24px;
    }

    b:last-child {
      font-size: 18px;
    }
  }
  p.title {
    font-weight: bold;
    font-size: 28px;
  }
}

.installments-option {
  width: 100%;
  input {
    margin-right: 5px;
  }

  label.left {
    margin-right: 80px;
    width: 145px;
  }
}

@media only screen and (max-width: 350px) {
  .content {
    img.product-logo {
      max-height: 40px;
    }

    img.lock {
      margin-top: 15px;
      margin-right: 15px;
    }
  }

  .product-info {
    p.title {
      font-size: 24px;
    }
  }

  .installments-option {
    label.left {
      width: 175px;
    }
    color: $public-blue;
  }
}

span.price {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.form-wrapper {
  margin: 20px;
}

.options {
  position: absolute;
  width: 100%;
  height: 270px;

  p.safe-buy {
    margin-top: 30px;
    background-color: $neutral-00;
    padding: 8px;
    height: 40px;
    border: 1px solid $neutral-20;
    text-align: center;
    color: $neutral-70;
  }

  .actions {
    button {
      position: fixed;
      bottom: 10px;
      width: 96.5%;
    }
  }

  button {
    margin-top: 25px;
    text-transform: uppercase;
    color: $neutral-70;
    padding: 8px;
    height: 40px;
    width: calc(96% - 2px);
    left: 1.65%;
    right: 2%;
    z-index: 2;

    background: $neutral-00;
    border: 1px solid $neutral-20;
    box-sizing: border-box;
    border-radius: 4px;

    span {
      margin-right: 3px;
    }
  }

  button:after {
    content: '';
    vertical-align: middle;
    display: inline-block;
  }

  button.alternate-payment-type {
    background: $neutral-00;
    border: 1px solid $public-blue;
    box-sizing: border-box;
    border-radius: 4px;
    color: $public-blue;
  }

  button.next-payment {
    color: $neutral-00;
    height: 42px;
    background: $blue-button-gradient;
    box-shadow: $default-box-shadow;
    border-radius: 4px;
    margin-bottom: 10px;
    margin-top: 15px;
  }
}

.observations {
  font-size: 12px;
  margin-top: 30px;
  text-align: center;

  p {
    margin-bottom: 8px;
  }

  div {
    a {
      margin-right: 10px;
    }
  }
}

.form-inline {
  display: inline-flex;

  .form-group:last-child {
    margin-left: 10px;
  }
}

.product-description {
  text-align: justify;
  padding: 5px 25px 5px 25px;
  color: $public-blue;
}

.card-container {
  margin: 35px 0px 35px 0px;
}
</style>
