import { createI18n } from 'vue-i18n';

import ptBr from '../assets/pt-br.json';
import enUs from '../assets/en-us.json';

const messages = {
  'en-US': enUs,
  'pt-BR': ptBr,
};

const i18n = createI18n({
  locale: navigator.language || navigator.userLanguage,
  fallbackLocale: 'en-US',
  messages,
});

export default i18n;
