import { createApp } from 'vue';

import Notifications from '@kyvg/vue3-notification';
import Maska from 'maska';

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import i18n from './plugins/i18n';

import App from './App.vue';
import router from './router';
import store from './store';

import AxiosConfig from './config/axios.config';

import 'bootstrap';

createApp(App)
  .use(router)
  .use(store)
  .use(i18n)
  .use(Notifications)
  .use(Maska)
  .component('Datepicker', Datepicker)
  .mount('#app');

AxiosConfig.configure();
