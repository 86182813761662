import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

class PaymentService {
  async submit(payment) {
    const options = {
      headers: {
        'content-type': 'application/json',
      },
    };

    return axios.post(API_URL + 'payments', payment, options);
  }
}

export default new PaymentService();
