import ClassroomService from '../services/classroom.service';

const initialState = {};

export const classroom = {
  namespaced: true,
  state: initialState,

  actions: {
    list({ commit }) {
      return ClassroomService.list().then(
        (data) => {
          commit('classroomListSuccess', data);
          return Promise.resolve(data);
        },
        (error) => {
          commit('classroomListFailure');
          return Promise.reject(error);
        }
      );
    },
  },

  mutations: {
    classroomListSuccess(state, classrooms) {
      classrooms.sort((a, b) => a.name.localeCompare(b.name));
      state.classrooms = classrooms;
    },

    classroomListFailure(state) {
      state.classrooms = null;
    },
  },
};
