import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

class OfferService {
  async create(offer) {
    const options = {
      headers: {
        'content-type': 'application/json',
      },
    };

    const targetProduct = offer.product;
    delete offer.product;

    const payload = {
      details: offer,
    };

    return axios.post(API_URL + 'products/' + targetProduct + '/offers', payload, options);
  }

  async update(offer) {
    const options = {
      headers: {
        'content-type': 'application/json',
      },
    };
    const existingOfferId = offer.existingOfferId;
    const targetProduct = offer.product;

    delete offer.product;
    delete offer.existingOfferId;

    const payload = {
      details: offer,
    };

    return axios.put(API_URL + 'products/' + targetProduct + '/offers/' + existingOfferId, payload, options);
  }

  async list() {
    const response = await axios.get(API_URL + 'offers');
    return Promise.resolve(response.data);
  }

  async retrieve(productId, offerId) {
    const response = await axios.get(`${API_URL}products/${productId}/offers/${offerId}`);
    return Promise.resolve(response.data);
  }

  async delete(productId, offerId) {
    return axios.delete(API_URL + 'products/' + productId + '/offers/' + offerId);
  }
}

export default new OfferService();
