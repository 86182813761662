<template>
  <HeaderComponent v-if="currentUser" />
  <MenuComponent v-if="currentUser" />
  <div>
    <router-view />
  </div>
</template>

<script>
import './scss/bootstrap-custom.scss';
import './scss/vue-datepicker-custom.scss';
import './scss/buttons.scss';
import './scss/common.scss';

import HeaderComponent from './components/HeaderComponent.vue';
import MenuComponent from './components/MenuComponent.vue';

export default {
  components: {
    HeaderComponent,
    MenuComponent,
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>
