import PaymentService from '../services/payment.service';

const initialState = {};

export const payment = {
  namespaced: true,
  state: initialState,

  actions: {
    submit({ commit }, newPayment) {
      return PaymentService.submit(newPayment).then(
        (data) => {
          commit('paymentSubmissionSuccess', data);
          return Promise.resolve(data);
        },
        (error) => {
          commit('paymentSubmissionFailure');
          return Promise.reject(error);
        }
      );
    },
  },

  mutations: {
    paymentSubmissionSuccess(state, payment) {
      state.productPayment = payment.data;
    },

    paymentSubmissionFailure(state) {
      state.productPayment = null;
    },
  },
};
