import { createWebHistory, createRouter } from 'vue-router';

import LoginComponent from './components/LoginComponent.vue';

import PublicPayment from './modules/public-payment/PaymentHome.vue';

const ProductHome = () => import('./modules/product/ProductHome.vue');
const ProductInclusion = () => import('./modules/product/Product-Inclusion.vue');

const OfferInclusion = () => import('./modules/offer/Offer-Inclusion.vue');

const PublicPaymentSuccess = () => import('./modules/public-payment/Payment-Executed.vue');

const SalesHome = () => import('./modules/sales/SalesHome.vue');

const NotFound = () => import('./components/Not-Found.vue');

const routes = [
  {
    path: '/',
    component: ProductHome,
  },
  {
    path: '/login',
    component: LoginComponent,
  },
  {
    path: '/product',
    component: ProductHome,
  },
  {
    path: '/product/new',
    component: ProductInclusion,
  },
  {
    path: '/product/sales',
    component: SalesHome,
  },
  {
    path: '/product/:productId/offer/:offerId',
    component: OfferInclusion,
  },
  {
    path: '/offer/new/',
    component: OfferInclusion,
  },
  {
    path: '/pay/:shortLink?',
    component: PublicPayment,
  },
  {
    path: '/paid',
    component: PublicPaymentSuccess,
  },
  {
    path: '/not-found',
    component: NotFound,
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const authRequired = !new RegExp('/pay/*|/login|/paid|/not-found').test(to.path);
  const loggedIn = localStorage.getItem('credentials');

  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router;
