import SalesService from '../services/sales.service';

const initialState = {};

export const sales = {
  namespaced: true,
  state: initialState,

  actions: {
    list({ commit }) {
      return SalesService.list().then(
        (data) => {
          commit('salesListSuccess', data);
          return Promise.resolve(data);
        },
        (error) => {
          commit('salesListFailure');
          return Promise.reject(error);
        }
      );
    },
  },

  mutations: {
    salesListSuccess(state, sales) {
      const salesFiltered = [];
      const salesMap = new Map();
      const salesArray = sales.data.items;

      salesArray.forEach((sale) => {
        if (!salesMap.get(sale.externalReference)) {
          salesMap.set(sale.externalReference, [sale]);
        } else {
          const salesArray = salesMap.get(sale.externalReference);
          salesArray.push(sale);
        }
      });

      for (const sale of salesMap.values()) {
        sale[0].totalInstallments = sale.length;
        salesFiltered.push(sale[0]);
      }

      salesFiltered.sort((a, b) => b.createdAt - a.createdAt);
      state.sales = salesFiltered;
    },

    salesListFailure(state) {
      state.sales = null;
    },
  },
};
