import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

class BalanceService {
  async getBalance() {
    const options = {
      headers: {
        'content-type': 'application/json',
      },
    };

    return axios.get(API_URL + 'balance', options);
  }

  async getBalanceStatistics() {
    const options = {
      headers: {
        'content-type': 'application/json',
      },
    };

    return axios.get(API_URL + 'balance-statistics', options);
  }
}

export default new BalanceService();
