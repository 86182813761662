import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

class ProductService {
  async uploadFile(fileData) {
    const file = fileData[0];

    let options = {
      headers: {
        accept: file.type,
      },
    };

    const response = await axios.get(API_URL + 'products-upload', options);
    const uploadInfo = response.data;

    const uploadFile = new File([file], uploadInfo.key);

    options = {
      headers: {
        'content-type': file.type,
      },
    };

    await axios.put(uploadInfo.url, uploadFile, options);

    return Promise.resolve(uploadInfo);
  }

  async create(product) {
    const options = {
      headers: {
        'content-type': 'application/json',
      },
    };

    if (product.logo) {
      product.logo = product.logo[0].name;
    }

    return axios.post(API_URL + 'products', product, options);
  }

  async list() {
    const response = await axios.get(API_URL + 'products');
    return Promise.resolve(response.data);
  }

  async delete(productId) {
    return axios.delete(API_URL + 'products/' + productId);
  }

  async findByShortLink(shortLink) {
    return axios.get(API_URL + 'offers/short-links/' + shortLink);
  }
}

export default new ProductService();
