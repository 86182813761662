import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'login', {
        username: user.username,
        password: user.password,
      })
      .then((response) => {
        let userDetails = {};
        if (response.data.token) {
          userDetails = {
            username: user.username,
            token: response.data.token,
          };

          localStorage.setItem('credentials', JSON.stringify(userDetails));
        }

        return userDetails;
      });
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
  }
}

export default new AuthService();
