import { createStore } from 'vuex';

import { auth } from './auth.module';
import { product } from './product.module';
import { classroom } from './classroom.module';
import { offer } from './offer.module';
import { payment } from './payment.module';
import { sales } from './sales.module';
import { balance } from './balance.module';

const store = createStore({
  modules: {
    auth,
    product,
    classroom,
    offer,
    payment,
    sales,
    balance,
  },
});

export default store;
