<template>
  <div id="main" class="header clear" v-if="currentUser">
    <div id="sidebar">
      <div class="user-information">
        <img :src="userLogo" alt="Header logo" />
        <span>Instituto Brasileiro de Formação de Terapeutas</span>
      </div>
    </div>
    <div id="page-wrap">
      <div class="header-actions">
        <img v-bind:src="logoutIcon" v-bind:alt="$t('logout')" @click.prevent="logout" icon="sign-in-alt" class="pointer" v-bind:title="$t('logout')" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userLogo: require('../assets/images/trg-full-logo.svg'),
      logoutIcon: require('../assets/images/logout.svg'),
    };
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },

  methods: {
    logout() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/variables.scss';

.header {
  position: absolute;
  left: 130px;
  right: 16px;
  top: 15px;
  height: 80px;
  background: $neutral-00;
  z-index: 1;
  border-top-right-radius: 40px;
}

#sidebar {
  width: 80%;
  float: left;
  padding-left: 16px;
  padding-top: 12px;
}

#page-wrap {
  width: 20%;
  min-height: 88px;
  float: left;
  text-align: right;
  padding-top: 25px;
  padding-right: 20px;
}

.svg-inline--fa {
  font-size: 25px;
  color: $neutral-60;
}

.user-information {
  font-weight: bold;
  font-size: 20px;
  color: $secondary;
}

.user-information span {
  margin-left: 16px;
}
</style>
