<template>
  <nav class="menu">
    <router-link to="/" class="nav-link">
      <img :src="zeusLogo" alt="Menu logo" />
    </router-link>
    <div class="menu-entry">
      <router-link to="/product" class="nav-link product-route">
        <img class="unselected" :src="productBox" />
        <img class="selected" :src="productBoxPink" />
        <p>{{ $t('products') }}</p>
      </router-link>
      <router-link to="/product/sales" class="nav-link offer-sales">
        <img class="unselected" :src="target" />
        <img class="selected" :src="targetOrange" />
        <p>{{ $t('sales') }}</p>
      </router-link>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      zeusLogo: require('../assets/images/zeus-white.svg'),
      productBox: require('../assets/images/product-box.svg'),
      productBoxPink: require('../assets/images/product-box-pink.svg'),
      label: require('../assets/images/label.svg'),
      target: require('../assets/images/target.svg'),
      targetOrange: require('../assets/images/target-orange.svg'),
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/variables.scss';

.menu {
  width: 130px;
  background-color: $blue-zeus;
  font-weight: bold;
  font-size: 16px;
  position: fixed;
  left: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  top: 0;

  img {
    margin: 20px 4px auto;

    &.selected {
      display: none;
    }
  }
}

.router-link-exact-active {
  &.product-route,
  &.offer-sales {
    background-color: $neutral-00;

    .product-route {
      color: $pink;
    }
  }
}

.router-link-exact-active.product-route {
  color: $pink;
  img {
    &.unselected {
      display: none;
    }
    &.selected {
      display: inline;
    }
  }
}

.router-link-exact-active.offer-sales {
  color: $primary;
  img {
    &.unselected {
      display: none;
    }
    &.selected {
      display: inline;
    }
  }
}

div.menu-entry {
  margin-top: 2em;
  width: 130px;
  text-align: center;

  a {
    color: $neutral-00;
    padding-top: 30px;
  }

  img {
    margin: 0 auto;
    display: block;
    margin-bottom: 5px;
  }

  .product-route {
    img {
      height: 30px;
      margin-top: 0;
    }
  }
}
</style>
